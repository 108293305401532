import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import "./hero.css"


export default () => (
    <StaticQuery
      query={graphql`
        query HeroQuery {
          file(name: { eq: "banner2" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
          <BackgroundImage
            Tag="section"
            className={`bgimg-home bg-overlay-black-4`}
            fluid={data.file.childImageSharp.fluid}
            backgroundColor={`#040e18`} >
            <div className="article-caption-home">
              <div className="article-text">
                <span className="article-border-head">Hi, I like websites<br /><span className="article-border-sub">Sometimes I make them too!</span></span>
              </div>
            </div>
          </BackgroundImage>
        )}
    />
  )
