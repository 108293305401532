import React from "react"
import Link from "gatsby-link"
import Counter from "./counter"
import Layout from "../components/layout"
import { graphql } from "gatsby";
import Hero from "../components/hero-old"
import SEO from "../components/SEO"
import Img from "gatsby-image"
import Cookies from "../components/cookiePreferences"

export default ({ data }) => (
    <Layout>
        <SEO title="JimTheFish starter site" description="This is my first ever crack at a Gatsby site.  I REALLY like it!" />
        <Hero />
        <div className="under-hero-content">
            <h1>{data.site.siteMetadata.title}</h1>
            <p>{data.site.siteMetadata.author}</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <Cookies />
            <p><Link to="/plugins">This is a link to my plugins page!</Link></p>
            <span className="content-image">
                <Img fluid={data.file.childImageSharp.fluid} />
            </span>
            <p>Aliquet sagittis id consectetur purus ut faucibus pulvinar. Fermentum iaculis eu non diam phasellus vestibulum lorem sed risus. Vitae auctor eu augue ut lectus arcu. Aliquam ultrices sagittis orci a scelerisque purus semper eget duis. Viverra justo nec ultrices dui sapien eget. Sit amet consectetur adipiscing elit ut aliquam purus sit amet. Integer malesuada nunc vel risus commodo viverra. Et netus et malesuada fames ac turpis egestas sed. Suspendisse ultrices gravida dictum fusce. Sed viverra tellus in hac habitasse platea dictumst. Porta nibh venenatis cras sed felis eget velit aliquet. Blandit turpis cursus in hac habitasse platea dictumst quisque sagittis. Suspendisse sed nisi lacus sed viverra tellus in hac. Bibendum neque egestas congue quisque egestas diam. Sem integer vitae justo eget magna fermentum. Nulla posuere sollicitudin aliquam ultrices sagittis orci a. Rhoncus mattis rhoncus urna neque viverra justo nec ultrices. Imperdiet sed euismod nisi porta lorem mollis aliquam.</p>
            <p>Sed euismod nisi porta lorem. Nec nam aliquam sem et. Ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at augue eget. Tortor pretium viverra suspendisse potenti nullam ac tortor. Commodo sed egestas egestas fringilla phasellus faucibus scelerisque eleifend. Facilisi cras fermentum odio eu feugiat pretium nibh ipsum. Ligula ullamcorper malesuada proin libero nunc consequat interdum. Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Mauris cursus mattis molestie a iaculis at. Donec ultrices tincidunt arcu non sodales neque sodales ut. Platea dictumst quisque sagittis purus sit. At ultrices mi tempus imperdiet nulla malesuada pellentesque elit eget. Viverra justo nec ultrices dui sapien eget mi proin. Ac odio tempor orci dapibus ultrices. Faucibus a pellentesque sit amet porttitor.</p>
            <p>Commodo ullamcorper a lacus vestibulum sed arcu. Morbi tincidunt ornare massa eget egestas purus viverra accumsan. Nulla posuere sollicitudin aliquam ultrices sagittis orci. Vulputate dignissim suspendisse in est. Lorem mollis aliquam ut porttitor leo a diam. Donec ultrices tincidunt arcu non sodales neque sodales ut. Nibh tellus molestie nunc non. Ac tortor dignissim convallis aenean. Ut diam quam nulla porttitor massa. Vitae congue eu consequat ac. Tincidunt augue interdum velit euismod in pellentesque massa. Iaculis at erat pellentesque adipiscing commodo. Netus et malesuada fames ac turpis egestas. Ultricies integer quis auctor elit sed vulputate mi. Integer vitae justo eget magna. Gravida in fermentum et sollicitudin. Enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra.</p>
            <p>Pellentesque dignissim enim sit amet venenatis urna. Tempus egestas sed sed risus pretium quam vulputate dignissim. Curabitur gravida arcu ac tortor. Porta non pulvinar neque laoreet suspendisse interdum. A diam sollicitudin tempor id. Lorem ipsum dolor sit amet consectetur adipiscing elit. Proin sagittis nisl rhoncus mattis rhoncus urna neque. Bibendum enim facilisis gravida neque convallis a cras semper auctor. Tortor pretium viverra suspendisse potenti nullam. Diam quam nulla porttitor massa id neque aliquam. Gravida quis blandit turpis cursus in.</p>
            <Counter color="green" />
        </div>
    </Layout>
)

export const query = graphql`
    query FirstQuery {
        site { 
            siteMetadata {
                title
                author
            }
        }
        file(name: { eq: "sea-boat" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 850) {
                ...GatsbyImageSharpFluid
              }
            }
        }
    }
`