import React, { Component } from "react"
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact"
import Cookie from 'universal-cookie'
import "../styles/global.css"

const cookie = new Cookie()

class ModalPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: cookie.get('cookie_consent') ? false : true,
      switch1: true,
      switch2: cookie.get('cookie_consent') ? cookie.get('cookie_consent').substring(1,2) === "1" ? true : false : false,
      switch3: cookie.get('cookie_consent') ? cookie.get('cookie_consent').substring(2,3) === "1" ? true : false : false,
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  handleSwitchChange = nr => () => {
    let switchNumber = `switch${nr}`
    this.setState({
      [switchNumber]: !this.state[switchNumber],
    })
  }


  handleCookieConsentChange = () => {
    let values = ("1" + (this.state.switch2 ? "1" : "0") + (this.state.switch3 ? "1" : "0"))
    let values2 = ('{"strictly-necessary":true,' + (this.state.switch2 ? '"functionality":true,' : '"functionality":false,') + (this.state.switch3 ? '"tracking":true,"targeting":true}' : '"tracking":false,"targeting":false}'))
    let sw2 = this.state.switch2
    let sw3 = this.state.switch3 
    cookie.set("cookie_consent", values, { path: "/", expires: new Date(Date.now() + 31540000000), sameSite: "Strict" })
    cookie.set("cookie_consent_level", values2, { path: "/", expires: new Date(Date.now() + 31540000000), sameSite: "Strict" })
    //console.log(this.state.consent)

    this.setState({
      switch1: true, 
      switch2: sw2, 
      switch3: sw3, 
      modal: false,
    })   
    //console.log("+++ CCC +++")
    this.handleScripts()
  }

  handleCookieConsentAll = () => {
    cookie.set("cookie_consent", "111", { path: "/", expires: new Date(Date.now() + 31540000000), sameSite: "Strict" })
    cookie.set("cookie_consent_level", '{"strictly-necessary":true,"functionality":true,"tracking":true,"targeting":true}', { path: "/", expires: new Date(Date.now() + 31540000000), sameSite: "Strict" })
    this.setState({
      switch1: true, 
      switch2: true, 
      switch3: true, 
      modal: false,
    })   
    //console.log("+++ ALL +++")
    this.handleScripts()
  }

  handleScripts = () => {
    // Remove Google Analytics script if it exists
    let allsuspects=document.getElementsByTagName("script");
    for (let i=allsuspects.length; i>=0; i--){
    if (allsuspects[i] && allsuspects[i].getAttribute("id")!==null 
      && allsuspects[i].getAttribute("id").indexOf("gaobj") !== -1 ){
             allsuspects[i].parentNode.removeChild(allsuspects[i])
          }    
      }
  
    // Add Google Analytics script
    var code = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){" + 
                "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o)," + 
                "m=s.getElementsByTagName(o)[0];a.defer=1;a.src=g;m.parentNode.insertBefore(a,m)" + 
                "})(window,document,'script','//www.google-analytics.com/analytics.js','ga');" + 
                "" +       
                "ga('create', 'UA-163834880-1', {" + 
                "  cookie_flags: 'max-age=7200;secure;samesite=none'" + 
                "});" + 
                "ga('set', 'anonymizeIp', true);" + 
                "ga('send', 'pageview');"

    const script = document.createElement("script")
    script.setAttribute("type", "text/javascript")
    script.setAttribute("id", "gaobj")
    script.appendChild(document.createTextNode(code))
    document.body.appendChild(script)

/*    this.state.switch1 === true && (
      console.log("neccessary")
    )

    this.state.switch2 === true && (
      console.log("functional")
    )
    

    this.state.switch1 === true && ( 
        console.log("tracking")
      ) */ 
  }

//  componentDidMount() {
//    this.setState({ consent: cookie.get('cookie_consent'), 
//  })
//  }

  componentDidMount() {
    //alert('This is component (DidMount) Firing!!!') 
    const pp = document.createElement("script")
    pp.src="//www.privacypolicies.com/public/cookie-consent/3.1.0/cookie-consent.js"
    document.head.appendChild(pp)
    //console.log("001")

    //console.log("+++ MOUNT +++")
    //console.log("Switch 1 = " + this.state.switch1)
    //console.log("Switch 2 = " + this.state.switch2)
    //console.log("Switch 3 = " + this.state.switch3)

    //call function to include scripts based on consent settings
    this.handleScripts()

//    const script = document.createElement("script")
//    script.src = '/test.js'
//    document.head.appendChild(script)
/*    var code = 'document.addEventListener("DOMContentLoaded", function () {' +
               'cookieconsent.run({"notice_banner_type":"headline","consent_type":"express","palette":"light","language":"en","website_name":"Hello World","change_preferences_selector":"#preferences"});' +
               '});'
    const script = document.createElement("script")
    script.setAttribute("type", "text/javascript")
    script.appendChild(document.createTextNode(code))
    document.head.appendChild(script) */
  }

  componentDidUpdate() {
    /* console.log("+++ UPDATE +++")
    console.log("Switch 1 = " + this.state.switch1)
    console.log("Switch 2 = " + this.state.switch2)
    console.log("Switch 3 = " + this.state.switch3) */
  }

  componentWillUnmount() {
    //alert('This is component (DidMount) Firing!!!') 
    //const scriptToRemove = '/test.js'
    const scriptToRemove = '//www.privacypolicies.com/public/cookie-consent/3.1.0/cookie-consent.js'
    let allsuspects=document.getElementsByTagName("script")
    for (let i=allsuspects.length; i>=0; i--){
    if (allsuspects[i] && allsuspects[i].getAttribute("src")!==null 
      && allsuspects[i].getAttribute("src").indexOf(`${scriptToRemove}`) !== -1 ){
              allsuspects[i].parentNode.removeChild(allsuspects[i])
            }    
        }

       /* console.log("+++ UNMOUNT +++")
        console.log("Switch 1 = " + this.state.switch1)
        console.log("Switch 2 = " + this.state.switch2)
        console.log("Switch 3 = " + this.state.switch3) */   
    } 



  render() {
    /* const pp = document.createElement("script")
    pp.src="//www.privacypolicies.com/public/cookie-consent/3.1.0/cookie-consent.js"
    //document.body.appendChild(pp)
    document.getElementsByTagName('body').item(0).appendChild(pp)
    console.log("001")

    var code = 'document.addEventListener("DOMContentLoaded", function () {' +
               'cookieconsent.run({"notice_banner_type":"headline","consent_type":"express","palette":"light","language":"en","website_name":"Hello World","change_preferences_selector":"#preferences"});' +
               '});'
    const script = document.createElement("script")
    script.setAttribute("type", "text/javascript")
    script.appendChild(document.createTextNode(code))
    //document.body.appendChild(script)
    document.getElementsByTagName('body').item(0).appendChild(script)
    console.log("002") */

    return (
        <>
        <p><i className="fa fa-angle-right"></i><a role="link" onClick={this.toggle}>Cookie Preferences</a></p>
        <MDBContainer>
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggle}
          //position="bottom-right"
          backdrop={true}
          disableBackdrop={true}
          keyboard={false}
          fullHeight
          position="left"
        >
          <MDBModalHeader toggle={this.toggle}>
              Your choice regarding cookies on this site
          </MDBModalHeader>
          <MDBModalBody>
              <p style={{ fontSize: "1.4rem", fontWeight: "400", color: "#000" }}>Your privacy is important to us</p>
              <p className="pref-body">
                Cookies are very small text files that are stored on your
                computer when you visit a website. We use cookies for a variety
                of purposes and to enhance your online experience on our website
                (for example, to remember your account login details).
              <br /><br />
                You can change your preferences and decline certain types of
                cookies to be stored on your computer while browsing our
                website. You can also remove any cookies already stored on your
                computer, but keep in mind that deleting cookies may prevent you
                from using parts of our website.
              </p>
              <p className="pref-header">Strictly necessary cookies</p>
              <p className="pref-body">
                These cookies are essential to provide you with services
                available through our website and to enable you to use certain
                features of our website.
              <br /><br />
                Without these cookies, we cannot provide you certain services on
                our website.
              </p>
              <div className="custom-control custom-switch pb-4">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="necessaryCookies"
                  checked={this.state.switch1}
//                  defaultChecked
                  readOnly
                />
                <label
                  className="custom-control-label"
                  htmlFor="necessaryCookies"
                  style={{paddingTop: "3px"}}
                >
                  Always active
                </label>
              </div>

              <p className="pref-header">Functionality cookies</p>
              <p className="pref-body">
                These cookies are used to provide you with a more personalized
                experience on our website and to remember choices you make when
                you use our website.
              <br /><br />
                For example, we may use functionality cookies to remember your
                language preferences or remember your login details.
              </p>
              <div className="custom-control custom-switch pb-4">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="functionalityCookies"
                  checked={this.state.switch2}
                  onChange={this.handleSwitchChange(2)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="functionalityCookies"
                  style={{paddingTop: "3px"}}
                >
                  {this.state.switch2 ? "Active" : "Inactive"}
                </label>
              </div>

              <p className="pref-header">Tracking and performance cookies</p>
              <p className="pref-body">
                These cookies are used to collect information to analyze the
                traffic to our website and how visitors are using our website.
              <br /><br />
                For example, these cookies may track things such as how long you
                spend on the website or the pages you visit which helps us to
                understand how we can improve our website site for you.
              <br /><br />
                The information collected through these tracking and performance
                cookies do not identify any individual visitor.
              </p>
              <div className="custom-control custom-switch pb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="trackingCookies"
                  checked={this.state.switch3}
                  onChange={this.handleSwitchChange(3)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="trackingCookies"
                  style={{paddingTop: "3px"}}
                >
                  {this.state.switch3 ? "Active" : "Inactive"}
                </label>
              </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn outline color="primary" size="sm" onClick={ this.handleCookieConsentChange }>
              Save preferences
            </MDBBtn>
            <MDBBtn color="primary" size="sm" onClick={ this.handleCookieConsentAll }>
              Accept all
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        </MDBContainer>
      </>
    )
  }
}

export default ModalPage